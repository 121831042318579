<template>
  <v-card elevation="0" :color="value.sended ? '#009b7724' : '#F3F3F3'">
    <v-card-title>
      <v-icon left> mdi-account </v-icon>
      <span class="font-weight-bold">{{ value.userInfo.fullName }}</span>
    </v-card-title>

    <v-card-text>
      <div v-html="value.body" style="white-space: pre-line" class="text-justify" />

      <files-display v-if="value.attachments" class="mt-4" :value="value.attachments" />
      <v-divider class="my-4" />

      <div class="d-flex justify-space-between align-center">
        <DateDisplay :value="value.created" />

        <v-icon v-if="value.seen" color="primary" small> mdi-check-all </v-icon>
        <v-icon v-else small> mdi-check </v-icon>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MessageBox',
  props: {
    value: {type: Object, default: () => ({})}
  }
}
</script>
